import React, { useEffect, useState } from "react"
import { Nav, Container, Col, Row } from "react-bootstrap"
import Logo from "../../images/icons/logo.svg"
import LogoLight from "../../images/icons/logo-light.svg"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import "./Header.scss"
import { GetPageURL } from "../common/utils"
import $ from "jquery"
import Offcanvas from "react-bootstrap/Offcanvas"
import { useLocation } from "react-use"

function Header(props) {
  // Mobile Menu state
  const [show, setShow] = useState(false)
  const [scroll, setScroll] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  //

  const pathName = useLocation().pathname

  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        burgerMenus {
          sort_order
          menu {
            label
            slug
            parent {
              slug
              label
              parent {
                slug
                label
              }
            }
          }
        }
      }
    }
  `)
  const menu_data = data.glstrapi.burgerMenus.sort((a, b) =>
    a.sort_order > b.sort_order ? 1 : -1
  )
  useEffect(() => {
    var scrolled = false
    $(window).scroll(function () {
      if ($(window).scrollTop() > 10 && scrolled == false) {
        $(".header").addClass("scrolled")
        scrolled = true
        setScroll(true)
      } else if ($(window).scrollTop() == 0) {
        $(".header").removeClass("scrolled")
        scrolled = false
        setScroll(false)
      }
    })
  }, [])
  return (
    <header
      className={`header fixed ${
        props.layout == "form_template" ? "transparent-header" : ""
      }`}
    >
      <Container
        fluid
        className="d-flex justify-content-between align-items-center"
      >
        <Link to="/" className="navbar-brand">
          {props.layout == "form_template" && scroll == false ? (
            <img src={LogoLight} alt="logo" className="brand-logo" />
          ) : (
            <img src={Logo} alt="logo" className="brand-logo" />
          )}
        </Link>
        <div className="nav-links">
          <ul className="d-none d-lg-flex">
            {menu_data?.map((item, index) => {
              const isActive = pathName?.includes(item?.menu?.slug)

              return (
                <li key={index}>
                  <Link
                    target={item?.target === "new_window" ? "_blank" : ""}
                    to={GetPageURL(item?.menu, item?.custom_link)}
                    className={isActive ? "active-page-link" : ""}
                  >
                    {item?.menu?.label}
                  </Link>
                </li>
              )
            })}
          </ul>
          <div className="mobilemenu d-lg-none d-xl-none">
            <ul>
              <li>
                <Link to="/how-clozesure-works/">How it Works</Link>
              </li>
              <li>
                <Link to="/contact-clozesure/">Contact Us</Link>
              </li>
            </ul>
            <div className="nav-btn d-block d-lg-none">
              <Link to="/" className="btn btn-pink">
                Instant Quote
              </Link>
            </div>
            <div className="burger-nav">
              <a href="javascript:;" onClick={handleShow}>
                <i className="icon-menu" onClick={handleShow}></i>
              </a>
              <Offcanvas show={show} onHide={handleClose} placement={`end`}>
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>
                    <Link to="/" className="navbar-brand">
                      {/* <img src={Logo} alt="logo" className="brand-logo" /> */}
                    </Link>
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <h4>NAVIGATE</h4>
                  {menu_data?.map((item, index) => {
                    return (
                      <li key={index}>
                        <Link
                          target={item?.target === "new_window" ? "_blank" : ""}
                          to={GetPageURL(item?.menu, item?.custom_link)}
                        >
                          {item?.menu?.label}
                        </Link>
                      </li>
                    )
                  })}
                  {/* <div className='address-menu-ft'>
                    <h4>CONTACT DETAILS</h4>
                    <p>31 Century Buildings, Esplanade, St. Helier,<br></br>Jersey, JE2 3AD</p>
                    <p>contact@clozesure.com<br></br></p>
                    <p><a href="tel:020 1234 5678">020 1234 5678</a><br></br></p>
                    <div className='find-us'>
                      <a href='https://www.linkedin.com/company/clozesure/' target={`_blank`}>
                        <span>Find us on Linkedin</span>
                        <i className='icon-linked-round'></i>
                      </a>
                    </div>
                  </div> */}
                </Offcanvas.Body>
              </Offcanvas>
            </div>
          </div>
          <div className="nav-btn d-none d-lg-block">
            <Link to="/" className="btn btn-pink">
              Instant Quote
            </Link>
          </div>
        </div>
      </Container>
    </header>
  )
}

export default Header
