import React, { useRef, useState, useEffect } from "react"
import { Form } from "react-bootstrap"
import Col from "react-bootstrap/Col"
import DatePicker from "react-datepicker"
import $ from "jquery"
import "react-datepicker/dist/react-datepicker.css"

const InputField = ({
  startDate,
  setStartDate,
  startTime,
  setStartTime,
  fieldClass,
  labelClass,
  min,
  max,
  type,
  name,
  value,
  required,
  placeholder,
  handlechange,
  pattern,
  label,
  grpmd,
  step,
  filedwrap,
  defaultValue,
}) => {
  const [isEditing, setEditing] = useState(false)
  const inputRef = useRef()

  const [userName, setUserName] = useState("")
  const [email, setEmail] = useState("")
  const [telephone, setTelephone] = useState("")

  useEffect(() => {
    // get name, email and telephone from local storage and set to state
    const name = localStorage.getItem("name")
    const email = localStorage.getItem("email")
    const telephone = localStorage.getItem("telephone")
    setUserName(name)
    setEmail(email)
    setTelephone(telephone)
  }, [])

  const toggleEditing = () => {
    setEditing(!isEditing)
    if (isEditing) {
      inputRef.current.focus()
    }
  }
  return (
    <>
      {type === "datetime" ? (
        <>
          <div className="form-field-group dtpicker">
            <label htmlFor="date" className="form-label">
              Preferred Date
            </label>
            <DatePicker
              selected={startDate}
              onChange={date => setStartDate(date)}
              minDate={new Date()}
              placeholderText={`Select your preferred date`}
              dateFormat="MMMM d, yyyy"
              className={fieldClass}
              required={required}
              autocomplete="off"
              name={name}
              autoComplete={"" + Math.random()}
              // onChange={handlechange}
              value={startDate}
            />
          </div>
          <div className="form-field-group dtpicker">
            <label htmlFor="time" className="form-label">
              Preferred Time
            </label>
            <DatePicker
              selected={startTime || startDate}
              showTimeSelect
              minDate={new Date()}
              placeholderText={`Select your preferred time`}
              dateFormat="h:mm aa"
              className={fieldClass}
              required={required}
              onChange={date => setStartTime(date)}
              showTimeSelectOnly
              autocomplete="off"
              name="time"
              autoComplete={"" + Math.random()}
              value={startTime}
            />
          </div>
        </>
      ) : (
        <Form.Group
          md={grpmd}
          className={`${type} ${step} ${
            filedwrap ? filedwrap : "form-field-group"
          }`}
          controlId={"validation" + name}
        >
          <div className="custom-float">
            {label ? (
              <Form.Label className="form-label">
                {label}
                {required ? "*" : ""}
              </Form.Label>
            ) : (
              ""
            )}

            <Form.Control
              className={fieldClass}
              required={required}
              type={type}
              name={name}
              value={
                name === "name"
                  ? userName
                  : name === "email"
                  ? email
                  : telephone
              }
              placeholder={placeholder}
              onChange={event => {
                handlechange(event)

                if (name === "name") {
                  setUserName(event.target.value)
                }
                if (name === "email") {
                  setEmail(event.target.value)
                }
                if (name === "telephone") {
                  if (event.target.value.match(pattern)  || event.target.value === "") {
                  setTelephone(event.target.value)
                  }
                }
              }}
              pattern={pattern}
              autocomplete="off"
              defaultValue={defaultValue || ``}
            />
          </div>
        </Form.Group>
      )}
    </>
  )
}
export default InputField
