import { graphql, Link, useStaticQuery } from "gatsby"
import React, { useEffect } from "react"
import "./Footer.scss"
import sbLogo from "../../images/icons/sb-logo.svg"
import { Container } from "react-bootstrap"
import { useMedia } from "react-use"
import { GetPageURL } from "../common/utils"
import CookieConsent from "react-cookie-consent"
import $ from "jquery"
import NewsletterFooterForm from "../forms/newsletter"
import { Helmet } from "react-helmet"
const Footer = () => {
  const isMobile = useMedia("(max-width: 768px)")

  const showbugherd = process.env.GATSBY_SHOW_BUGHERD

  const data = useStaticQuery(graphql`
    query GetFooterData {
      glstrapi {
        socialLink {
          footer_menu {
            id
            title
            footer_links {
              custom_link
              id
              label
              link_type
              target
              menu_item {
                label
                link_type
                slug
                parent {
                  slug
                  label
                  parent {
                    slug
                    label
                    parent {
                      slug
                      label
                    }
                  }
                }
                id
              }
            }
          }
        }
        siteConfig {
          twitter_link
          linkedin_link
          instagram_link
          facebook_link
          github_link
          Footer_Logo {
            url
          }
        }
      }
    }
  `)

  const footerMenuData = data.glstrapi.socialLink.footer_menu
  const footerSocialData = data.glstrapi.siteConfig
  // Cookie Trigger
  useEffect(() => {
    setTimeout(function () {
      if ($(".cookie-popup .CookieConsent").length > 0) {
        $(".footer-wrapper").addClass("with_cookie-popup")
      }
      $(".cookieok").click(function (event) {
        $(".acceptcookie").click()
      })
    }, 500)
  }, [])
  //
  const currentYear = new Date().getFullYear()

  return (
    <>
      {showbugherd == "true" && (
        <Helmet>
          <script
            type="text/javascript"
            src="https://www.bugherd.com/sidebarv2.js?apikey=jab6hfztajvvbbnp0pmw5a"
            async="true"
          ></script>
        </Helmet>
      )}
      <div className="footer-wrapper">
        <Container className="footer-container">
          <div className="footer-top">
            <div className="socials-wrap">
              {footerSocialData?.Footer_Logo && (
                <Link to="/">
                  <img
                    className="logo"
                    src={footerSocialData?.Footer_Logo?.url}
                    alt="clozesure logo"
                  />
                </Link>
              )}
              <div className="socials">
                {footerSocialData?.linkedin_link && (
                  <a target="_blank" href={footerSocialData?.linkedin_link} className="social">
                    <i className="icon icon-linkedin" />
                  </a>
                )}
                {footerSocialData?.facebook_link && (
                  <a target="_blank" href={footerSocialData?.facebook_link} className="social">
                    <i className="icon icon-newFb" />
                  </a>
                )}
                {footerSocialData?.twitter_link && (
                  <a target="_blank" href={footerSocialData?.twitter_link} className="social">
                    <i className="icon icon-newtwitter" />
                  </a>
                )}
                {footerSocialData?.instagram_link && (
                  <a target="_blank" href={footerSocialData?.instagram_link} className="social">
                    <i className="icon icon-insta" />
                  </a>
                )}
              </div>
              <NewsletterFooterForm />
              {/* <div className="subscribe">
              <NewsletterFooterForm />
              <input type="text" placeholder="Email Address" />
              <button className="btn btn-grey">Subscribe</button>
            </div> */}
            </div>
            <div className="menus-wrap">
              {footerMenuData.length > 0 &&
                footerMenuData.map(menu => (
                  <div className="menu-list-wrap">
                    <p className="title">{menu.title}</p>
                    <div className="menu-list">
                      {menu.footer_links?.length > 0 &&
                        menu.footer_links.map(link => (
                          <Link
                            target={
                              link?.target === "new_window" ? "_blank" : ""
                            }
                            to={GetPageURL(link.menu_item, link.custom_link)}
                            className="list-item"
                          >
                            {link.label}
                          </Link>
                        ))}
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="Fdivider"></div>
          <div className="footer-end">
            <div className="terms-section">
              <p className="copyright">© {currentYear} ClozeSure</p>
              <a href="/privacy/">Privacy</a>
              <a href="/terms-and-conditions/">
                {isMobile ? "T&C's" : "Terms and Conditions"}
              </a>
            </div>
            <div className="site-by">
              Site by:{" "}
              <a href="https://starberry.tv/">
                <img src={sbLogo} alt="Starberry Logo" />
              </a>
            </div>
          </div>
          <section className="cookie-popup">
            <CookieConsent
              location="bottom"
              buttonText="Accept Cookies"
              cookieName="CookiePolicy"
              buttonClasses="acceptcookie"
              overlay={false}
              // acceptOnScrollPercentage={1}
              //onAccept={()=> ClosePopup()}
            >
              <div className="topbar-block">
                <p>
                  We have placed cookies on your device to help make this
                  website better. By continuing, you agree to our{" "}
                  <Link to="/cookie-policy/">Cookie Policy</Link>.{" "}
                  <a href="javascript:void(0);" class="cookieok">
                    Accept Cookies
                  </a>
                </p>
                <i className="icon-close-dark"></i>
              </div>
            </CookieConsent>
          </section>
        </Container>
      </div>
    </>
  )
}

export default Footer
