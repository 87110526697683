import axios from 'axios';


const baseURL = `${process.env.GATSBY_STRAPI_FORM_URL}`;
const token = process.env.GATSBY_STRAPI_FORM_TOKEN;

const authInstance = axios.create({
  baseURL: baseURL,
  headers: {
    Authorization: `Bearer ${token}`,
    // "Content-Type": "multipart/form-data"
  },
});


export const postFileData = async (data) => {

  const res = await authInstance.post('upload', data)
  .then((res) => {
    // Success
    if (res.statusText === 'OK') {
      return {
        success: true,
        ...res.data,
      }
    }
    return { success: false }
  })
  .catch((error) => {
    // Failed
    if (error.response) {
      return {
        success: false,
        message: error.response.data,
      }
    } else {
      // Service error
    }
  })

  return res;

}

export const postFormData = async (data) => {

  const res = await authInstance.post('forms', data)
  .then((res) => {
    // Success
    if (res.statusText === 'OK') {
      return {
        success: true,
        ...res.data,
      }
    }
    return { success: false }
  })
  .catch((error) => {
    // Failed
    if (error.response) {
      return {
        success: false,
        message: error.response.data,
      }
    } else {
      // Service error
    }
  })

  return res;

}

export const hubspot = ()=>{
    createContact()
}

const propertyData = {
  saleprice: "500000",
  address1: "Four Gables",
  address2: "Rosemary Lane",
  address3: "Thorpe",
  postcode: "TW8 8QE",
}

// Define the base URL for the HubSpot API
const baseUrl = 'https://api.hubapi.com';

// Define the headers for the API requests
const headers = {
'accept': 'application/json',
'content-type': 'application/json',
'authorization': 'Bearer pat-eu1-0409183f-ad9c-48c5-88e9-db9dfa307057',
'Referrer-Policy': 'strict-origin-when-cross-origin',
'Referer': 'https://clozesure.q.starberry.com'
};

const contactData = {
  properties: {
    email: "phil@beville.com",
    lifecyclestage: 'lead'
  }
};



const createContact = async () => {
  try {
    const searchOptions = {
      method: 'post',
      url: `https://api.hubapi.com/crm/v3/objects/contacts/search`,
      headers: {
        'accept': 'application/json',
        'content-type': 'application/json',
        'authorization': 'Bearer pat-eu1-0409183f-ad9c-48c5-88e9-db9dfa307057',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
        'Access-Control-Allow-Headers':  'Origin, X-Requested-With, Content-Type, Accept',
        'Referrer-Policy': 'strict-origin-when-cross-origin'
        },
      data: {
        filterGroups: [
          {
            filters: [
              {
                operator: 'EQ',
                propertyName: 'email',
                value: "test@gmail.com"
              }
            ]
          }
        ]
      }
    };

    console.log("popop");
    const searchResponse = await axios(searchOptions);
    console.log(searchResponse, "searchResponse");
  } catch (error) {
    // Handle any errors
    console.error(error.message, "error");
  }
};
